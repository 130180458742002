export default function (data = {}) {
  const {
    percent = '',
    type = ''
  } = data
  return {
    percent,
    type
  }
}
