import { createDateOfTimeStamp, createTimeOfTimeStamp } from '@/Methods/GlobalMethods'
import { OFFER_TYPE_NAMES } from '@/constants/requests'
import Decimal from 'decimal.js'
import Store from '@/store'

export default class {
  constructor (data) {
    this._serverOfferData = data
    const { request } = data
    const { in: input, out: output, input_output_type: inputOutputType } = request
    const { amount: inputAmountObj } = input
    const { amount: outputAmountObj } = output
    this.date = createDateOfTimeStamp(input.timestamp)
    this.time = createTimeOfTimeStamp(input.timestamp)
    this.timestamp = input.timestamp
    this.offerType = inputOutputType
    this.inputAmount = inputAmountObj.amount
    this.inputCurrency = Store.state.tokensNameMap[inputAmountObj.currency_type]?.code || inputAmountObj.currency_type
    this.outputAmount = outputAmountObj.amount
    this.outputCurrency = Store.state.tokensNameMap[outputAmountObj.currency_type]?.code || outputAmountObj.currency_type
    if (this.offerType === OFFER_TYPE_NAMES.INPUT) {
      this.ownedAmount = this.inputAmount
      this.ownedCurrency = this.inputCurrency
      this.otherAmount = this.outputAmount
      this.otherCurrency = this.outputCurrency
      this.otherUserInfo = output.user_account_info
      this.ownedUserInfo = input.user_account_info
    } else {
      this.ownedAmount = this.outputAmount
      this.ownedCurrency = this.outputCurrency
      this.otherAmount = this.inputAmount
      this.otherCurrency = this.inputCurrency
      this.otherUserInfo = input.user_account_info
      this.ownedUserInfo = output.user_account_info
    }
    this.offerStatus = data.offer_status
    this.operationId = data.operation_id
    this.invertCourse = false
  }

  get course() {
    const value = !this.invertCourse ? new Decimal(this.outputAmount).div(this.inputAmount) : new Decimal(this.inputAmount).div(this.outputAmount)
    return value.toFixed().toString()
  }

  get coursePair() {
    let firstCurrency, secondCurrency
    if (this.offerType === OFFER_TYPE_NAMES.INPUT) {
      firstCurrency = this.ownedCurrency
      secondCurrency = this.otherCurrency
    } else {
      firstCurrency = this.otherCurrency
      secondCurrency = this.ownedCurrency
    }
    return !this.invertCourse ? `${firstCurrency}/${secondCurrency}` : `${secondCurrency}/${firstCurrency}`
  }

  get offerStatuses() {
    return this.offerStatus?.reduce((acc, status) => {
      acc[status] = true
      return acc
    }, {}) || {}
  }

  get completed() {
    return this.offerStatuses.OfferCompleted || false
  }
  get accepted() {
    return this.offerStatuses.OfferAccepted || false
  }
  get rejected() {
    return this.offerStatuses.OfferRejected || false
  }
  get canceled() {
    return this.offerStatuses.OfferCanceled || false
  }
}
