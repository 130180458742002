import { AE_OPERATION_TYPE_NAMES } from '@/constants/auto-exchange'
import moment from 'moment-mini'
import Decimal from 'decimal.js'
import { PAYMENT_REASON_LABELS } from '@/constants/P2P'
import Store from '@/store'

export default function (data) {
  const {
    operation_id,
    session_id,
    timestamp,
    type,
    first_amount,
    second_amount,
    first_currency,
    second_currency,
    exchange_rate,
    offer_operation_id,
    banknotes,
    city,
    country,
    min_match_amount,
    status,
    additional_info,
    user_account_info,
    payment_types,
    operation_type,
    payment_reason,
    response_offer_op_id,
    user_id,
    product_details,
    ready_to_pay_for,
    mktu_class,
    mktu_numbers
  } = data || {}
  const result = {
    banknotes,
    city,
    country,
    exchange_rate,
    min_match_amount,
    operation_id,
    first_amount,
    first_currency,
    second_amount,
    second_currency,
    session_id,
    type,
    offer_operation_id,
    additional_info,
    status,
    timestamp,
    user_account_info,
    payment_types,
    operation_type,
    payment_reason,
    response_offer_op_id,
    user_id,
    product_details,
    ready_to_pay_for,
    mktu_class,
    mktu_numbers
  }
  result.paymentReasonLabel = PAYMENT_REASON_LABELS[payment_reason] || ''
  result.minMatchAmountDisplay = Number(min_match_amount).toLocaleString()
  result.isBill = result.operation_type === AE_OPERATION_TYPE_NAMES.AUTOEXCHANGE_BILL
  result.firstAmountDisplay = Number(result.first_amount).toLocaleString()
  result.firstCurrencyShort = Store.state.tokensNameMap[result.first_currency]?.code
  result.secondAmountDisplay = Number(result.second_amount).toLocaleString()
  result.secondCurrencyShort = Store.state.tokensNameMap[result.second_currency]?.code
  result.banksInfo = additional_info.banks?.join(', ')
  result.mktuInfo = mktu_numbers?.map(number => {
    return Store.state.Admin[number.length < 3 ? 'mktuClassesMap' : 'mktuProductsMap'][number]
  }).flat()
  result.mktuClasses = Object.keys((mktu_numbers || []).reduce((acc, number) => {
    let className = ''
    if (number.length < 3) className = number
    else if (number.length === 5) className = number[0]
    else className = number.slice(0, 2)
    acc[className] = ''
    return acc
  }, {}))
  result._time = moment.unix(timestamp).format('DD/MM/YY HH:mm')
  result._rateCurrency = `${result.firstCurrencyShort}/${result.secondCurrencyShort}`
  result._offer = `${result.first_amount !== '0' ? result.first_amount : ''} ${result.firstCurrencyShort}`
  result._price = result.exchange_rate
  return result
}
