import { getDeepSortedPropsObj } from '@/utils/common'
import { SignService } from '@/Services/SignService'
import { actionSignature } from '@/Methods/ActionSignature'

export default class P2PDealBaseSignModel {
  constructor ({ channel, chaincode, action, data }) {
    this.cryptInfo = { channel, chaincode, action }
    this.data = data
  }

  getSignedRequest (key, userId) {
    const data = getDeepSortedPropsObj(this.data)
    let signService = new SignService(key, userId)
    let actionId = actionSignature(
      this.cryptInfo.channel,
      this.cryptInfo.chaincode,
      this.cryptInfo.action
    )
    const signed_request = signService.createSignedRequest(actionId, data)
    return {
      signed_request
    }
  }
}
