export const TOKEN_TYPES = {
  BITCOIN_FORK: 'BitcoinFork',
  ETHEREUM_FORK: 'EthereumFork',
  FIAT: 'Fiat',
  FIAT_CASH: 'FiatCash',
  OTHER: 'Other'
}

export default class Token {
  constructor (data) {
    this.code = data.code
    this.external_wallet_available = data.external_wallet_available
    this.name = data.name
    this.token_type = data.token_type
  }

  get isFiatCash () {
    return this.token_type === TOKEN_TYPES.FIAT_CASH
  }
  get isFiat () {
    return this.token_type === TOKEN_TYPES.FIAT
  }
  get isMessageToken () {
    return this.name === 'MessageToken'
  }

}
